import React from "react";

function CopyrightSign() {
  const currentYear = new Date().getFullYear(); // Gets the current year

  return (
    <footer className="footer">
      <p>
        &copy; {currentYear} TheCelestialFilms. Powered by TheCelestialFilms.
      </p>
    </footer>
  );
}

export default CopyrightSign;
