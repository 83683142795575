import "./styles.css";
import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Work from './Work';  
import Services from './Services'; 
import Contact from './Contact'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import TransparentNavbar from './TransparentNavbar';
import CopyrightSign from './CopyrightSign'

export const Context = createContext();
export default function App() {
  useEffect(() => {
    document.title = 'The Celestial Films';
  }, []); 
  return (
    <Router>
    <div className="App" >
    <TransparentNavbar/>
    <div style={{ height: '1000px', paddingTop: '20px' }}>

      <Routes>
          <Route path="/" element={<Work />} />
          <Route path="/work" element={<Work />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
    </div>
      <CopyrightSign/>
    </div>
    </Router>
  );
}
