import React from "react";
import videoProductionImage from "../src/assets/services/1.jpeg";
import photographyImage from "../src/assets/services/2.jpeg";
import digitalMarketingImage from "../src/assets/services/3.jpeg";

function Services() {
  return (
    <div className="services">
      <h1
        className="service-h1"
        style={{ textAlign: "center", paddingBottom: "2.5%" }}
      >
        Welcome to Celestial Films
      </h1>
      <h4 className="sub-header">
        Here we unlock your brand’s narrative potential in the digital cosmos.
        With a focus on visual storytelling, we craft engaging experiences that
        resonate with your audience and inspire action.
      </h4>

      <div className="service-section service-section-left">
        <img
          src={videoProductionImage}
          alt="Video Production"
          className="service-image"
        />
        <div className="service-content">
          <h2>Video Production</h2>
          <p>
            In an era where visual storytelling reigns supreme, Celestial Films
            emerges as the vanguard of narrative innovation. Just as the advent
            of video transformed the landscape of communication, we harness the
            power of cinematic expression to illuminate your brand's story in
            the digital cosmos. Recent data speaks volumes: a staggering 85% of
            internet users engage with video content on a monthly basis,
            underscoring its undeniable impact on modern consumer behavior.
            Surveyed individuals express a clear appetite for promotional films
            from the brands they patronize, recognizing the persuasive potency
            of visual narratives in informing their purchasing decisions. At
            Celestial Films, we don't just tell stories; we craft experiences
            that resonate deeply with your audience. Our dedicated team at
            Celestial Films meticulously dissects your message, weaving together
            a narrative tapestry that not only captivates but also inspires
            trust in your products or services, compelling viewers to take
            action.
          </p>
        </div>
      </div>

      <div className="service-section service-section-right">
        <div className="service-content">
          <h2>Photography</h2>
          <p>
            At Celestial Films, we understand that images speak volumes. That's
            why we believe in the power of high-quality photography to enhance
            your brand's story and captivate your audience.We begin every
            photoshoot by listening. What are your objectives? How will the
            images be used? Our team collaborates closely with you to understand
            your goals and requirements, ensuring that each image we capture
            aligns perfectly with your vision.With expertise, experience, and
            top-of-the-line equipment, our photography team delivers stunning
            results, whether it's architectural photos or product shots. We
            pride ourselves on our ability to bring out the best in every
            subject, creating imagery that resonates with your audience and sets
            you apart from the competition.
          </p>
        </div>
        <img
          src={photographyImage}
          alt="Photography"
          className="service-image"
        />
      </div>

      <div className="service-section service-section-left">
        <img
          src={digitalMarketingImage}
          alt="Digital Marketing"
          className="service-image"
        />
        <div className="service-content">
          <h2>Digital Marketing</h2>
          <p>
            Digital advertising isn’t just about banner ads anymore—it’s a
            dynamic ecosystem that spans across various platforms and devices.
            With Celestial Films, we dive deep into the intricacies of digital
            marketing to ensure your message reaches the right audience, at the
            right time, and in the most impactful way possible.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
