// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { FaInstagram, FaFacebookF } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import Logo from "../src/assets/FINAL-Nishant-014-1.png";
// import { Navbar, Nav, Container } from "react-bootstrap";

// function TransparentNavbar() {
//   const location = useLocation();
//   const [navStyle, setNavStyle] = useState("bg-transparent");

//   const handleScroll = () => {
//     const show = window.scrollY > 50;
//     if (show) {
//       setNavStyle("translucent-navbar");
//     } else {
//       setNavStyle("bg-transparent");
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const whiteTextRoutes = ["/", "/work"];
//   const textColor = "text-dark"; //whiteTextRoutes.some(route => location.pathname === route) ? "text-white" : "text-dark";

//   return (
//     <Navbar expand="lg" className={`${navStyle} navbar-light`}>
//       <Container fluid className="nav-bg">
//         <Navbar.Brand as={Link} to="/">
//           <img
//             src={Logo}
//             alt="Logo"
//             className="logo"
//             style={{ height: "220px" }}
//           />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link
//               as={Link}
//               to="/work"
//               className={textColor}
//               //   style={{ fontSize: "1.5rem" }}
//             >
//               Work
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/services"
//               className={textColor}
//               //   style={{ fontSize: "1.5rem" }}
//             >
//               Services
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/contact"
//               className={textColor}
//               //   style={{ fontSize: "1.5rem" }}
//             >
//               Contact
//             </Nav.Link>
//           </Nav>
//           <Nav className="me-auto">
//             <Nav.Link
//               href="https://www.instagram.com/nishantsinghani"
//               className={`link ${textColor}`}
//             >
//               <FaInstagram />
//             </Nav.Link>
//             <Nav.Link
//               href="https://www.facebook.com/nishantsinghaniphotography"
//               className={`link ${textColor}`}
//             >
//               <span style={{ marginRight: "6px" }}>
//                 <FaFacebookF />
//               </span>
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default TransparentNavbar;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../src/assets/FINAL-Nishant-014-1.png";
import { Navbar, Nav, Container } from "react-bootstrap";

function TransparentNavbar() {
  const location = useLocation();
  const [navStyle, setNavStyle] = useState("bg-transparent");
  const [expanded, setExpanded] = useState(false); // State to manage Navbar expansion

  const handleScroll = () => {
    const show = window.scrollY > 50;
    if (show) {
      setNavStyle("translucent-navbar");
    } else {
      setNavStyle("bg-transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleNavbar = () => setExpanded((prevExpanded) => !prevExpanded);
  const closeNavbar = () => setExpanded(false);

  return (
    <Navbar
      expand="lg"
      className={`${navStyle} navbar-light`}
      expanded={expanded}
    >
      <Container fluid className="nav-bg">
        <Navbar.Brand as={Link} to="/" onClick={closeNavbar}>
          <img
            src={Logo}
            alt="Logo"
            className="logo"
            style={{ height: "220px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" onClick={closeNavbar}>
            <Nav.Link
              as={Link}
              to="/work"
              // className={textColor}
            >
              Work
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/services"
              // className={textColor}
            >
              Services
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              // className={textColor}
            >
              Contact
            </Nav.Link>
          </Nav>
          <Nav className="me-auto" onClick={closeNavbar}>
            <Nav.Link href="https://www.instagram.com/nishantsinghani">
              <FaInstagram />
            </Nav.Link>
            <Nav.Link href="https://www.facebook.com/nishantsinghaniphotography">
              <FaFacebookF />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TransparentNavbar;
