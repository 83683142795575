 //dCdLUNwaoCk

import React from 'react';
import './styles.css';  // Import the CSS for styling

function YouTubeBackground() {
    // The YouTube video ID
    const videoId = "dCdLUNwaoCk";

    return (
        <div className="video-background">
            <iframe style = {{
            }}
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&showinfo=0&autohide=1&loop=1&playlist=${videoId}&mute=1&iv_load_policy=3&modestbranding=1`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Video Background"
            ></iframe>
        </div>
    );
}

export default YouTubeBackground;


