import FullScreenVideo from "./FullScreenVideo";
import About from './About'
import Clients from './Clients'
import WorkItems from './WorkItems'
function Work() {
    return (
      <div>
        <FullScreenVideo/>
        <div style={{background: '#f8f9fa' }}>
        <About/>
        <WorkItems/>
        <Clients/>
        </div>
      </div>

    );
  }
  
  export default Work;