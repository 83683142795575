import { useEffect } from "react";

function Notifications({ notificationStatus, setNotificationStatus }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotificationStatus("");
    }, 4000);
  }, [notificationStatus]);

  return (
    <div className="notification-container">
      <div
        id="notification"
        className="notifications"
        style={{ position: "absolute" }}
      >
        {notificationStatus === "success" ? (
          <div
            className="notification"
            style={{
              background: "green",
              color: "white",
              padding: "5px",
              borderRadius: "4px",
              width: "180px",
            }}
          >
            Submitted successfully
          </div>
        ) : (
          notificationStatus === "failure" && (
            <div
              className="notification"
              style={{
                background: "red",
                color: "white",
                padding: "5px",
                borderRadius: "4px",
                width: "180px",
              }}
            >
              Failed to submit
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Notifications;
