import React, { useState } from 'react';
import './styles.css'; // Assuming you will create a separate CSS file for styles

const WorkItems = () => {
    const videoIds = [
        'dCdLUNwaoCk', 'oYX_7EGfqDY', '3FnpcNBqcAs', 'XPzg_jCD6Rk',
        '_H8w004Cv2s', 'fxsNJ-Q_YWg', 'ic80SHh5y0s', '-LZ-X-piNHw',
        'yM4J6CzVuwA', '3lgQg5mCy_A', 'd4wBVwD2OaI', 'JDYoSz0o2lI',
        '6tkioxN3n58', 'PUcXhTNry8o', '61sApSPxJkI', 'PQFl1Qc9o08',
        'pZVoVAPnH1w'
    ];

    const [hovered, setHovered] = useState(null);

    return (
        <div className="thumbnail-grid">
            {videoIds.map(id => (
                <div key={id} className="thumbnail-item"
                    onMouseEnter={() => setHovered(id)}
                    onMouseLeave={() => setHovered(null)}>
                    <a href={`https://www.youtube.com/watch?v=${id}`} target="_blank" rel="noopener noreferrer">
                        <img src={`https://img.youtube.com/vi/${id}/0.jpg`} alt="YouTube Video Thumbnail" />
                        {hovered === id && (
                            <iframe
                                src={`https://www.youtube.com/embed/${id}?autoplay=1&mute=1`}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="YouTube video player"
                                className="video-preview"
                            ></iframe>
                        )}
                    </a>
                </div>
            ))}
        </div>
    );
};

export default WorkItems;
