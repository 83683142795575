import React from "react";
import "./styles.css"; // Assuming your CSS file is named Clients.css

import img1 from "../src/assets/27c8d1832de6a3123b6ee45b59ae2f81b0d9d0d0.png";
import img2 from "../src/assets/logo-1.png";
import img3 from "../src/assets/UTDB-ENG-preview.png";
import img4 from "../src/assets/328232152_1336484980480537_3689367780180078495_n.png";
import img5 from "../src/assets/Ministry_of_Information_and_Broadcasting.svg.png";
import img6 from "../src/assets/P6xOAm7YXbMl3g2bzapfFrHwy0kkhpTYkRiYdm4F (1).png";
import img7 from "../src/assets/tqr7Q82B_400x400.png";
import img8 from "../src/assets/Treebo-Logo-2_1_1.png";
import img9 from "../src/assets/download-1.png";
import img10 from "../src/assets/download.png";
import img11 from "../src/assets/LOGO.png";
import img12 from "../src/assets/900px-OYO_Rooms_logo.png";
import img13 from "../src/assets/Makemytrip_logo.svg.png";

function Clients() {
  // Placeholder array with image paths or URLs
  const clientImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
  ];

  return (
    <div className="clients-section">
      <h1 className="clients-h1">Our Clients</h1>
      <div className="client-images">
        {clientImages.map((image, index) => (
          <img
            className="clientImage"
            key={index}
            src={image}
            alt={`Client ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Clients;
