import React, { useState } from "react";
import myImg from "../src/assets/IMG_7927-1-3-scaled.jpg";
import Notifications from "./Notifications";

function Contact() {
  const [form, setForm] = useState({
    f_name: "",
    l_name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const [notificationStatus, setNotificationStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formUrl =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdKln-a28yFpboPyDgPmrNodFikmVny5a6VgjAHzx5BnrBpDQ/formResponse";
    const formDataToSubmit = new URLSearchParams({
      "entry.1690834037": form.f_name,
      "entry.108283929": form.l_name,
      "entry.378851137": form.email,
      "entry.1834397534": form.subject,
      "entry.2098995680": form.message,
      fvv: 1,
      pageHistory: 0,
    });

    try {
      await fetch(formUrl, {
        method: "POST",
        body: formDataToSubmit,
        mode: "no-cors", // Important to prevent CORS errors
      });
      setNotificationStatus("success");
      console.log("Form submitted successfully");
      // Optionally reset form or handle post-submit logic
      setForm({
        f_name: "",
        l_name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      setNotificationStatus("failure");
      console.error("Error submitting form", error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
        <Notifications
            notificationStatus={notificationStatus}
            setNotificationStatus={setNotificationStatus}
          />
          <img
            src={myImg}
            alt="Profile"
            className="img-fluid profile-photo"
            style={{ width: "290px", height: "auto" }}
          />
        </div>
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    name="f_name"
                    className="form-control"
                    placeholder="First Name"
                    value={form.f_name}
                    onChange={handleInputChange}
                    style={{
                      fontSize: "18px",
                      padding: "15px 12px",
                      marginBottom: "15px",
                    }}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="l_name"
                    className="form-control"
                    placeholder="Last Name"
                    value={form.l_name}
                    onChange={handleInputChange}
                    style={{
                      fontSize: "18px",
                      padding: "15px 12px",
                      marginBottom: "15px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={form.email}
                onChange={handleInputChange}
                style={{
                  fontSize: "18px",
                  padding: "15px 12px",
                  marginBottom: "15px",
                }}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Subject"
                value={form.subject}
                onChange={handleInputChange}
                style={{
                  fontSize: "18px",
                  padding: "15px 12px",
                  marginBottom: "15px",
                }}
              />
            </div>
            <div className="mb-3">
              <textarea
                name="message"
                className="form-control"
                placeholder="Message"
                rows="3"
                value={form.message}
                onChange={handleInputChange}
                style={{
                  fontSize: "18px",
                  padding: "15px 12px",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              ></textarea>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
